.yellowCircle {
    border-radius: 50%;
    background-color: #F0C63D;
    width: 4.44vw;
    height: 4.44vw;
}

.timeline {
    width: 100vw;
    /* margin-lef+t:3vw; */
    /* margin-left: -5vw; */
    margin-bottom: 5vw;
    margin-top: 48px;
}

.mob_timeline {
    display: none;
}

@media(max-width:700px) {
    .timeline {
        display: none;
    }

    .mob_timeline {
        margin-top: 10px;

        width: 80vw;
        display: block;
    }
}


.tl_timeline {
    display: flex;
    justify-content: center;
}

.tl_group2 {
    display: flex;
    width: 300px;
}

.tl_group1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#tl_rotated_group {
    transform: scaleY(-1);
    margin-top: 30px;
    /* margin-bottom:20px; */
}

.tl_rotated_text {
    transform: scaleY(-1);
    /* margin-top: -20px; */
}

#linee {
    width: 100%;
    color: black;
    position: absolute;
    top: 165px;
}

#tl_circle1 {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #252525;
    z-index: 4;
    margin-bottom: 0;
}

#tl_line1 {
    width: 2px;
    height: 120px;
    background: #7c7b7b;
    z-index: 2;
    margin-bottom: 0;
    margin-top: 0;
}

#tl_circle2 {
    width: 54px;
    height: 54px;
    border-radius: 50%;
    background: #F0C63D;
    z-index: 4;
    margin-bottom: 0;
}

#tl_line2 {
    width: 2px;
    height: 70px;
    background: #D9D9D9;
    background: #7c7b7b;
    z-index: 2;
    margin-bottom: 0;
    margin-top: 0;
}

#tl_group3 {
    display: flex;
    flex-direction: column;
    overflow-x: visible;
    width: 300px;
    /* background-color: #502525; */
}

#tl_image {
    position: relative;
    /* left: 7.9vw; */
    left: 114px;
    bottom: 24px;
    width: 118px;
    margin-top: 0;
    margin-bottom: 0;
    /* margin-top:-24px; */
    /* margin-left:8.5vw; */
}

.tl_Head {
    width: fit-content;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 0;
    text-align: center;
}


.tl_Text {
    /* width: fit-content; */
    height: fit-content;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 18px;
    color: #4f4f4f;
    position: relative;
    top: 66px;
    right: 6vw;
    /* background-color: #F0C63D; */
}

.tl_hr {
    position: relative;
    bottom: 220px;
    width: 102%;
    height: 4px;
    background: #252525;
    z-index: 2;
}

@media(max-width:2500px) {
    #tl_image {
        left: 125px;
    }

    .tl_Text {
        right: 4vw;
    }
}

@media(max-width:1500px) {
    #tl_image {
        left: 120px;
    }

    .tl_Text {
        right: 7vw;
    }
}

@media(max-width:1500px) {
    #tl_image {
        left: 115px;
    }
}

@media(max-width:1350px) {
    #tl_image {
        left: 116px;
    }

    .tl_Head {
        width: 18vw;
    }
}

@media(max-width:1270px) {
    #tl_image {
        left: 122px;
    }

    .tl_Head {
        width: 20vw;
    }

    .tl_Text {
        /* left:2vw; */

    }
}


@media(max-width:1200px) {
    #tl_image {
        left: 118px;
        width: 110px;
    }

    #tl_circle2 {
        width: 50px;
        height: 50px;
    }

    .tl_Head {
        /* width: 1vw; */
    }

    #linee {
        top: 160px;
    }
}


@media(max-width:1150px) {
    #tl_image {
        left: 126px;
    }

    .tl_Head {
        width: 22vw;
    }

    #tl_group3 {
        width: 350px;
        /* overflow-x: hidden; */
    }
}

@media(max-width:800px) {
    .tl_timeline {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .tl_group2 {
        transform: rotate(-90deg);
        margin-left: -8vw;
    }

    #tl_rotated_group {
        transform: rotate(90deg);
        margin-top: -180px;
        margin-bottom: -100px;
        margin-left: 10vw;
    }

    .tl_Head {
        transform: rotate(90deg);
        position: relative;
        left: 10vw;
        top: 10vw;
        width: 20vw;
        font-size: 1.2rem;
    }

    .tl_Text {
        transform: rotate(90deg);
        right: 15vw;
        width: 12vw;
        top: 12vw;
    }

    .tl_rotated_text {
        transform: rotate(-90deg);
    }

    #tl_circle2 {
        height: 56px;
        width: 56px;
    }

    #tl_image {
        width: 122px;
        left: 116px;
    }

    .tl_image2 {
        width: 122px;
        position: relative;
        transform: rotate(180deg);
        left: 11px;
    }
}