.FAQ {
    display: flex;
    flex-direction: column;
    padding: 116px 8vw 40px 6vw;
    /* margin-bottom: 240px; */
}

#Faqs {
    margin-bottom: 24px;
}
#bullets{
    font-family: 'Montserrat';
}

.FAQ ul {
    padding-left: 1vw;
}

.FAQ ul li {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    /* line-height: 20px; */
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 8px;
    list-style-position: inside;
}

.question {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 1.1rem;
    /* line-height: 20px; */
    color: rgba(0, 0, 0, 0.9);
    margin-bottom: 12px;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
}

.answer {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    width: 99%;
    /* line-height: 20px; */
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 16px;
}

.faqLine {
    background: #D9D9D9;
    border-radius: 2px;
    border-bottom-width: 2px;
    margin-bottom: 16px;
    margin-top: 0;
}



@media(max-width:2500px) and (min-width:1500px) {
    .question {
        font-size: 1.6rem;
    }

    .answer {
        font-size: 1.4rem;
    }
    .snoo{
        font-size: 1.4rem;
    }

    .FAQ {
        /* margin-bottom: 280px; */
    }
}

@media(max-width:800px) {
    .FAQ {
        padding-left: 8vw;
        padding-right: 7vw;
    }
}

@media(max-width:600px) {
    .FAQ {
        padding-left: 6vw;
        padding-right: 5vw;
        padding-top: 6rem;
        /* padding-bottom: 360px; */
    }
}