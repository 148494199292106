@import url('https://fonts.googleapis.com/css2?family=Manrope&family=Montserrat:wght@200;300;400;500;600&family=Poppins:wght@200;300;400;500&family=Sora:wght@200;300;400;500&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow-x: hidden;
}

::-webkit-scrollbar {
    display: none;
}

.example {
    scrollbar-width: none;
}

.navbar {
    z-index: 10;
    width: 100%;
    height: 60px;
    position: fixed;
    left: 0px;
    top: 0px;
    background: #191919;
    display: flex;
    align-items: center;
    justify-items: center;
    padding-left: 3.8vw;
    padding-right: 2vw;
    overflow: hidden;
    
}

.navFlex1,
.navFlexLogin {
    display: flex;
    gap: 2vw;

    /* margin-top: 6px; */
    /* z-index:5; */
}

.navFlexLogin {
    margin-left: 5vw;
}

#navFaq {
    margin-top: 6px;
}

.navScroll {
    /* margin-top: 4px; */
    width: fit-content;
    font-family: 'Sora';
    font-style: normal;
    font-weight: 500;
    font-size: 1.7rem;
    line-height: 40px;
    color: #FFFFFF;
    text-decoration: none;
    position: relative;

    /* top: 4px; */
}

.navbar a {
    text-decoration: none;
    color: #FFFFFF;
}

.navDot {
    color: #FAC949;
}

.navHead {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 0.96rem;
    line-height: 24px;
    /* margin-right: 4vw; */
    cursor: pointer;
    color: #FFFFFF ;
    display: block;
}

.navDbT,
.navDbC {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 0.96rem;
    line-height: 24px;
    color: #FFFFFF;
    /* margin-right: 4vw; */
    cursor: pointer;
    display: none;
}

#ca_db {
    position: relative;
    right: 2vw;
}

.liTitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 24px;
    color: #FAC949;
    margin-right: 1vw;
    text-decoration: underline;
    margin-top: 6px;
}

.logout {
    cursor: pointer;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 24px;
    color: #FAC949;
    margin-right: 1vw;
    text-decoration: underline;
}

.navTitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 24px;
    color: #FFFFFF;
    margin-right: 2vw;
}

.navFlex1 a {
    text-decoration: none;
    color: #FFFFFF;
}

.navFlex1 a.active {
    color: #FAC949;
}

#navdomain {
    color: #FFFFFF;
}

.navFlex2 {
    display: flex;
    align-items: center;
}

.navFlex3 {
    display: flex;
    display: none;
    /* margin-top: 6px; */
}
.navRegister{
    margin-bottom: 0px !important;
    color: #191919 !important;

}

.navRegister,
.liRegister {
    width: 8.5vw;
    height: 38px;
    background: #FAC949;
    border: none;
    outline: none;
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    margin-right: 1.5vw;
    margin-bottom: 12px;
}

.liRegister {
    color: #FFFFFF;
}

.navLogin{
    margin-bottom: 0px !important;
    color: #FAC949 !important;
}
.navLogin,
.liLogin {
    width: 8.5vw;
    height: 38px;
    border: 1px solid #FAC949;
    outline: none;
    background: none;
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 12px;
    color: #FFFFFF;
}

.liLogin {
    color: #FAC949;
}

#menu,
#navMore,
#navMore2 {
    display: none;
}

#uli {
    width: 100%;
    background-color: #FFFFFF;
    position: fixed;
    transition: all .5s;
    top: 0px;
    right: 0px;
    width: 0vw;
    height: 100vh;
    flex-direction: column;
    padding-top: 60px;
    font-weight: 600;
    z-index: 8;
    display: flex;
    display: none;
}

#crossimg {
    margin-top: 20px;
    margin-left: 46vw;
    width: 30px;
    height: 30px;
    margin-bottom: 20px;
    cursor: pointer;
}

#uli ul {
    margin-left: 0px;
    list-style: none;
    display: flex;
    flex-direction: column;
    padding-left: 8vw;
}

#uli ul li {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 24px;
    margin-bottom: 4px;
    cursor: pointer;
}

#uli ul a {
    text-decoration: none;
    color: black;
}

#uli ul a.active {
    color: #FAC949
}

#dropdown {
    display: none;
    background-color: white;
    color: #191919 !important;
    /* position: fixed; */
    /* top: 50px; */
    /* left: 20vw; */
    flex-direction: column;
    font-weight: 600;
    z-index: 8;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 12;
}

#moreDD {
    /* left: 40vw; */
    background-color: white;
    color: #191919 !important;
    position: fixed;
    /* top: 50px; */
    /* left: 44vw; */
    flex-direction: column;
    font-weight: 600;
    z-index: 8;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 12;
    display: none;
}

#moreDD2 {
    display: none;
    background-color: white;
    color: #191919 !important;
    position: fixed;
    /* top: 50px; */
    flex-direction: column;
    font-weight: 600;
    z-index: 8;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 12;
}

#dropdown ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding-left: 0.5vw;
}

#moreDD ul,
#moreDD2 ul {
    display: none;
}

#dropdown ul li {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 18px;
    padding: 4px 8px;
    width: 14vw;
    cursor: pointer;
    color: #191919;
}

#moreDD ul li,
#moreDD2 ul li {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 18px;
    padding: 4px 8px;
    width: 8rem;
    cursor: pointer;
    color: #191919;
}

#dropdown ul a,
#moreDD ul a,
#moreDD2 ul a {
    text-decoration: none;
}

#dropdown ul li:hover,
#moreDD ul li:hover,
#moreDD2 ul li:hover {
    background-color: #979595;
}

#team,
#ca,
#liTitle,
#liLogout {
    display: none;
}

#liTitle,
#liLogout {
    display: none;
    color: #FAC949;
}

#liLogout {
    cursor: pointer;
}

#liDropdown {
    overflow: hidden;
    padding-bottom: 12px;
}

#liDropdown ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
}

#liDropdown ul li {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 16px;
}

#liDropdown ul a {
    text-decoration: none;
    color: black;
}

#liDropdown ul a.active {
    text-decoration: underline;
}

#domainIcon {
    position: relative;
    /* left: 12px; */
    width: 12px;
    height: 12px;
    cursor: pointer;
}

#domain {
    width: 24px;
    height: 24px;
    color: #FFFFFF;
    cursor: pointer;
}

@media(max-width:2500px) and (min-width:1500px) {

    .navHead,
    .navDbC,
    .navDbT,
    .navTitle,
    .logout {
        font-size: 1.2rem;
    }

    .navScroll {
        font-size: 2rem;
        top: 4px;
        /* margin-top: 4px; */
    }

    .navRegister,
    .navLogin {
        width: 7vw;
        font-size: 1.2rem;
    }

    #dropdown {
        /* left: 28vw; */
    }

    #dropdown ul li {
        width: 10vw;
    }

    .navFlexLogin {
        /* margin-left: 10vw; */
    }
}

@media(max-width:2000px) and (min-width:1500px) {
    #dropdown {
        /* left: 24vw; */
    }
    .navScroll {
        /* margin-top: 4px; */
    }

    #dropdown ul li {
        width: 12vw;
    }
}

@media(max-width:1700px) and (min-width:1500px) {

    .navScroll {
        /* margin-top: 4px; */
    }
    #dropdown ul li {
        width: 13vw;

    }
}

@media(max-width:1380px) {
    .navScroll {
        /* margin-top: 4px; */
    }

    #dropdown {
        /* left: 24vw; */
    }
}

@media(max-width:1160px) {
    #dropdown ul li {
        width: 16vw;
    }
}

@media(max-width:1100px) {
    .navFlexLogin {
        /* margin-left: 3vw; */
        gap:1.5vw;
    }
}

@media(max-width:1010px) {
    #dropdown {
        /* left: 27.4vw; */
    }

    #dropdown ul li {
        width: 18vw;
    }

}

@media(max-width:980px) {
    .navFlexLogin {
        /* margin-left: 2vw; */
        gap: 1.2vw;
    }
    .navFlexLogin p {
        font-size: 0.9rem;
    }
}

@media(max-width:1168px) {
    .navRegister,.navLogin {
        width: 10vw;
    }

    #navRule,
    #navRule2,
    #navCA,
    #navFaq {
        display: none;
    }

    #navMore {
        display: block;
        color: #FFFFFF;
        cursor: pointer;
        z-index: 10;
        margin-left: -0vw;
    }

    #navMore2 {
        display: block;
        color: #FFFFFF;
        cursor: pointer;
        z-index: 10;
        /* margin-left: -5vw; */
    }

    #moreDD ul,
    #moreDD2 ul {
        display: flex;
        flex-direction: column;
        list-style: none;
        padding-left: 0.5vw;
    }

    #moreDD {
        /* left: 42.8vw; */
    }

    #moreDD2 {
        /* left: 55vw; */
    }

    /* .navLogin {
        width: 12vw;
    } */

    #dropdown {
        /* left: 27vw; */
    }

    #dropdown ul li {
        width: 20vw;
    }

    .navFlexLogin {
        gap: 2vw;
    }
}

@media(max-width:970px) {
    #moreDD {
        /* left: 45vw; */
    }

}
@media(max-width:870px) {
    #moreDD {
        /* left: 50vw; */
    }
    #moreDD2 {
        /* left: 64vw; */
    }
    .navFlexLogin {
        margin-left: 3.5vw;
    }
}

@media(max-width:800px) {
    #moreDD2 {
        /* left: 67vw; */
    }
}

@media(max-width:760px) {
    #moreDD {
        /* left: 58vw; */
    }

    .navRegister,
    .navLogin {
        width: 12vw;
        height: 34px;
    }

    .navFlexLogin {
        margin-left: 3vw;
    }

}

@media(max-width:725px) {
    .navFlexLogin {
        margin-left: 1vw;
        gap: 1.7vw;
    }
    #navUpdate{
        font-size: 0.88rem;
    }

    #navMore2 {
        /* margin-left: -5vw; */
    }

    #moreDD2 {
        /* left: 68vw; */
    }
}

@media(max-width:690px) {
    .navScroll {
        font-size: 1.5rem;
    }

    .navFlex1 {
        margin-left: -2vw;
    }

    .navRegister,
    .navLogin {
        width: 11vw;
        font-size: 0.8rem;
    }

    #moreDD {
        /* left: 60vw; */
    }

    .navFlexLogin p,
    .navTitle,
    .logout {
        font-size: 0.85rem;
    }

    .navTitle {
        margin-right: 1.5vw;
    }

    #moreDD ul li,
    #moreDD2 ul li {
        width: 8rem;
    }

}

@media(max-width:612px) {

    .navScroll {
        font-size: 1.5rem;
        min-width:20rem !important;
    }
    .navFlex1,
    .navRegister,
    .navLogin,
    .navTitle {
        display: none;
        visibility: hidden;
    }

    .navFlexLogin {
        display: none;
        visibility: hidden;
    }

    .navFlex3 {
        visibility: hidden;
    }

    .navDbC,
    .navDbT {
        display: none;
        visibility: hidden;
    }

    #menu {
        display: block;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 16px;
        right: 5vw;
        cursor: pointer;
    }

    #menu2 {
        display: block;
        display: none;
        width: 24px;
        height: 24px;
        margin-top: -4px;
        margin-bottom: 6px;
        cursor: pointer;
    }

    .navScroll {
        font-size: 1.8rem;
    }

    .liRegister,
    .liLogin {
        width: 30vw;

    }

    .liRegister {
        margin-bottom: 0;
    }

    #uli {
        display: flex;
        width: 0;
    }

    #moreDD ul,
    #moreDD2 ul {
        display: none;
    }
}

@media(max-width:450px) {
    #menu {
        margin-left: 50vw;
    }

    #crossimg {
        margin-left: 42vw;
    }
}

@media(max-width:410px) {

    #uli ul li {
        margin-bottom: 2px;
    }

    #liDropdown ul li {
        padding-bottom: 0px;
    }

    .liRegister {
        margin-bottom: 4px;
    }
}

@media(max-width:360px) {
    #menu {
        margin-left: 30vw;
    }

    #uli {
        width: 70vw;
    }

    .liLogin,
    .liRegister {
        width: 36vw;
    }

    #liDropdown {
        width: 50vw;
    }
}