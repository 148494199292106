.updates {
    display: flex;
    flex-direction: column;
    padding: 116px 8vw 40px 6vw;
    margin-bottom: 240px;
}

#update {
    margin-bottom: 24px;
}

.updCard {
    background-color: #EEF1FF;
    /* width:80vw; */
    padding: 16px 2vw 16px 2vw;
    border-radius: 4px;
    margin-bottom: 24px;

}

.updHead {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    /* line-height: 20px; */
    color: #000000;
    margin-bottom: 8px;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
}

.updText {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    color: #000000;
    margin-top: 0;
}


@media(max-width:600px) {
    .updates {
        padding-left: 6vw;
        padding-right: 5vw;
    }
}

@media(max-width:2500px) and (min-width:1500px) {
    .updHead {
        font-size: 1.6rem;

    }

    .updText {
        font-size: 1.3rem;
    }

    .updates {
        margin-bottom: 280px;
    }
}

@media(max-width:520px) {
    .updates {
        padding-bottom: 100px;
    }
}

@media(max-width:600px) {
    .updates {
        padding-bottom: 300px;
    }
}