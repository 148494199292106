.dbOuterDiv {
    display: flex;
    flex-direction: column;
    padding: 116px 6vw 40px 8vw;
    /* align-items: center; */
    /* margin-bottom: 240px; */
}
.dbFlex1{
    width: 46vw;
}

#dbHHead{
width: auto;
}

.dashboard {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 1.8rem;
    /* line-height: 37px; */
    color: #000000;
}

.dbHead1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #999999;
    margin-top: 0px;
    margin-bottom: 8px;
}

.dbHR1 {
    background: #D9D9D9;
    border-radius: 2px;
    /* border-style: none; */
    border-bottom-width: 2px;
    margin-bottom: 36px;
    z-index: 2;
}

.dbHR2 {
    background: #D9D9D9;
    border-radius: 2px;
    width: 48vw;
    margin-bottom: 32px;
}

.dbBtns {
    display: flex;

}

.dbCancel {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 20px;
    color: #000000;
    width: 8vw;
    height: 40px;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    margin-right: 1.5vw;
}

.dbSave {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 20px;
    color: #FFFFFF;
    width: 8vw;
    height: 40px;
    background: #1A73E8;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
}

.dbSave:disabled {
    opacity: 0.5;
}

.dbFlex1 {
    display: flex;
    gap: 24px;
    align-items: center;
    margin-bottom: 0.5rem;
}

.dbHead {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 20px;
    color: #000000;
    width: 20vw;
    /* margin-bottom: 8px; */
}

.dbText {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 15px;
    color: #999999;
    width: 18vw;
}

.teamID_box {
    width: 24vw;
    /* height: 40px; */
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 24px;
    color: #999999;
    text-transform: capitalize;
    padding: 8px;
    white-space: pre-wrap;
    word-wrap: break-word;

}

.synopsis {
    text-transform: none;
    width: 24vw;
    /* height: 40px; */
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 15px;
    color: #999999;
    padding: 8px;
    cursor: pointer;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.synopsis>a {
    color: #999999;
}

.file_box {
    width: 11vw;
    height: 80px;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    margin-left: 8vw;
    padding-top: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

#uplPaper1 {
    width: 11vw;
    height: 80px;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    margin-left: 8vw;
    padding-top: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#uplPaper2 {
    width: 24vw;
    height: 40px;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 15px;
    color: #999999;
    text-transform: capitalize;
    padding: 10px 2vw 10px 2vw;
    margin-left: 8vw;
}

.uploadText {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 10px;
    color: #000000;
    cursor: pointer;
}

#editFile,
#remove {
    cursor: pointer;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 16px;
    color: #B00020;
    margin-top: 4px;
    margin-left: 1vw;
    /* width:24vw; */
}

#dbFiles {
    display: flex;
    flex-direction: column;
}

.fileFlex {
    display: flex;
    width: 24vw;
    justify-content: flex-end;
}

.fileIcon {
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-bottom: 8px;
}

#caTable {
    border-collapse: separate;
    border-spacing: 0 16px;
}

.member_box {
    width: 46vw;
    height: 40px;
    margin-bottom: 16px;
    background: #F3F5F6;
}

.member_box>td {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #999999;
    margin-bottom: 0;
    text-align: center;
}

.member_box_data {
    /* display: flex; */
    width: 46vw;
    margin-top: 24px;
    margin-bottom: 12px;
    border-bottom: 1px solid #D9D9D9;
    /* padding-left: 0; */
    /* background-color: #1A73E8; */
}

.member_box_data>td {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 20px;
    color: #000;
    white-space: pre-wrap;
    word-wrap: break-word;
    border-bottom: 1px solid #D9D9D9;
    text-align: center;
}

#rank {
    position: relative;
}

.team_box_data {
    width: 46vw;
    margin-top: 16px;
    margin-bottom: 12px;
}

table {
    width: 46vw;
    table-layout: fixed;
}

.team_box_data td,
.team_box_data p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    color: #000;
    white-space: pre-wrap;
    word-wrap: break-word;
    border: 0.5px solid #D9D9D9;
    padding: 4px;
    text-align: center;
    margin-bottom: 0;
}

/* dialog */

#dbDialog {
    position: absolute;
    top: 250px;
    right: 6vw;
    width: 22vw;
    /* height: 160px; */
    background: #F3F5F6;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px 1vw;
    z-index: 5;
}
#dbdial{
    background: #F3F5F6;

}

#dialImg {
    width: 48px;
    height: 48px;
    margin-bottom: 16px;
}

#dialText1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 17px;
    color: #31DCA9;
    margin-bottom: 4px;
}
#dialtt{
    font-family: 'Montserrat';

}

#dialText2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 17px;
    color: #252525;
    text-align: center;
    margin-bottom: 0;
}

#dialText3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 0.7rem;
    line-height: 14px;
    color: #252525;
    text-align: center;
    margin-bottom: 0;
    display: none;
}

@media(max-width:2500px) and (min-width:1500px) {
    .dashboard {
        font-size: 2.7rem;
    }

    .dbOuterDiv {
        padding-bottom: 160px;
    }

    .dbHead1 {
        font-size: 1.5rem;
    }

    .dbHead {
        font-size: 1.4rem;
    }

    .dbText {
        font-size: 1.1rem;
        line-height: 24px;
    }

    .teamID_box,
    .synopsis {
        width: 20vw;
        /* height: 52px; */
        font-size: 1.2rem;
    }

    .member_box,
    .member_box_data,
    .team_box_data,
    table {
        width: 41vw;
        height: 52px;
    }

    .team_box_data td,
    .team_box_data p {
        font-size: 1.4rem;
    }

    .member_box td,
    .member_box_data td {
        font-size: 1.4rem;
    }

    .dbSave,
    .dbCancel {
        font-size: 1.2rem;
        width: 7vw;
        height: 50px;
    }

    .file_box {
        height: 120px;
    }

    #dbDialog {
        padding: 32px 1vw;
        width: 20vw;
    }

    #dialText1 {
        font-size: 1.6rem;
        margin-bottom: 12px;
        line-height: 28px;
    }

    #dialText2 {
        font-size: 1.4rem;
        line-height: 30px
    }

    #dialImg {
        width: 56px;
        height: 56px;
    }

    .fileIcon {
        width: 30px;
        height: 30px;
    }

    .uploadText {
        font-size: 1.2rem;
        line-height: 24px;
    }
}

@media(max-width:2500px) and (min-width:1500px) {

    .teamID_box,
    .synopsis {
        width: 24vw;
    }

    #dbDialog {
        width: 24vw;
    }

    .file_box {
        width: 12vw;
    }

    .member_box,
    .member_box_data,
    .team_box_data,
    table {
        width: 45vw;
        height: 52px;
    }
}

@media(max-width:1200px) {
    .dbFlex1{
        width: 50vw;
    }
    .member_box {
        padding-top: 8px;
    }

    .dbCancel,
    .dbSave {
        width: 10vw;
    }

    .dbHead {
        width: 25vw;
    }

    .member_box,
    .member_box_data,
    .team_box_data,
    table {
        width: 51vw;
    }

    #dbDialog {
        width: 24vw;
    }

    .dbHR2 {
        width: 53vw;
    }
}

@media(max-width:1130px) {
    #dbDialog {
        padding: 24px 1vw;
    }
    .dbFlex1{
        width: 54vw;
    }
}

@media(max-width:1000px) {

    .dbCancel,
    .dbSave {
        width: 10vw;
    }

    #dbDialog {
        width: 26vw;
    }

    .file_box {
        width: 14vw;
    }

    .teamID_box,
    .synopsis {
        width: 30vw;
    }

    .dbHead {
        width: 20vw;
    }

    .dbHR2 {
        width: 55vw;
    }

    .member_box,
    .member_box_data,
    .team_box_data,
    table {
        width: 52vw;
    }
}

@media(max-width:950px) {

    .dbCancel,
    .dbSave {
        width: 12vw;
    }

    .member_box,
    .member_box_data,
    .team_box_data,
    table {
        width: 53vw;
    }
}

@media(max-width:900px) {

    .teamID_box,
    .synopsis {
        width: 32vw;
    }

    .member_box,
    .member_box_data,
    .team_box_data,
    table {
        width: 55vw;
    }

    #dbDialog {
        width: 28vw;
        padding: 16px 1vw;
        right: 4vw;
    }

    .dbOuterDiv {
        padding-left: 6vw;
        padding-right: 4vw;
    }

    .dbHR2 {
        width: 58vw;
    }
}

@media(max-width:800px) {
    .dbText {
        width: 18vw;
    }

    .file_box {
        width: 18vw;
    }

    .member_box>p,
    .team_box_data>p {
        font-size: 0.9rem;
    }

    .member_box,
    .member_box_data,
    .team_box_data,
    table {
        width: 55vw;
    }

    #dbDialog {
        /* width: 30vw; */
        padding: 8px 1vw;
        /* right: 2vw; */
    }

    .teamID_box,
    .synopsis {
        margin-left: 0;
    }

    #dialText1 {
        font-size: 0.9rem;
    }

    #dialText2 {
        font-size: 0.8rem;
    }

    #dialImg {
        width: 24px;
        height: 24px;
        margin-bottom: 8px;
    }
}

@media(max-width:600px) {
    .dbText {
        width: 20vw;
        margin-right: 0;
    }

    .dbFlex1 {
        gap: 0;
    }

    .teamID_box,
    .synopsis {
        /* margin-left: 4vw; */
        width: 32vw;
    }

    .file_box {
        width: 20vw;
    }

    .dbSave,
    .dbCancel {
        width: 14vw;
    }

    .dbHead {
        width: 25vw;
    }

    .member_box,
    .member_box_data,
    .team_box_data,
    table {
        width: 58vw;
    }

    .dbHR2 {
        width: 60vw;
    }
    .dbFlex1{
        width: 60vw;
    }

    .dbOuterDiv {
        /* padding-bottom: 360px; */
    }
}

@media(max-width:500px) {

    .teamID_box,
    .synopsis {
        width: 50vw;
        margin-left: 4vw;
    }

    .dbSave,
    .dbCancel {
        width: 22vw;
        margin-right: 2vw;
    }

    .dbHead {
        width: 30vw;
    }

    .dbText {
        width: 30vw;
    }

    .dbHR2 {
        width: 88vw;
    }
    .dbFlex1{
        width: 84vw;
    }

    .member_box,
    .member_box_data,
    .team_box_data,
    table {
        width: 84vw;
    }

    .file_box {
        width: 28vw;
    }
    #dialText1 {
        font-size: 0.8rem;
    }

    #dialText2 {
        display: none;
    }

    #dialText3 {
        display: flex;
    }

    #dbDialog {
        top: 70px;

        right: 2vw;
        width: 34vw;
        position: static;
        display: none;
    }
}