html {
    height: 100%;
    box-sizing: border-box;
}


body {
    position: relative;
    /* padding-bottom: 40px; */
    min-height: 100%;
    box-sizing: border-box;
}

#footflex3{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

}
#footflex3{
    display: flex;
    flex-direction: column;
    gap: 0.5rem !important;

}
#loadSpinner {
    z-index: 9999;
    position: fixed;
    top: 45vh;
    left: 50vw;
}

#soonDialog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 160px;
    width: 30vw;
    text-align: center;
}

/* landing page */
.landingPage {
    z-index: 5;
    display: flex;
    flex-direction: column;
    /* margin-bottom: 310px !important; */
    padding-bottom: 10px !important;
    /* padding-bottom: 200px !important; */
    height: fit-content;
}

.landBlock1 {
    width: 100%;
    height: 620px;
    z-index: 1;
    margin-top: 60px;
}

#loginBg {
    background: rgba(0, 0, 0);
    width: 100%;
    height: 590px;
    z-index: 2;
    position: absolute;
    top: 60px;
    left: 0;
    filter: brightness(40%);
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.landImage {
    z-index: 1;
    opacity: 0.4;
    overflow: hidden;
}

.landFlex {
    position: absolute;
    top: 120px;
    left: 20vw;
    text-align: center;
    z-index: 2;
}

.landText1 {
    z-index: 2;
    width: 60vw;
    /* height: 216px; */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 3.8rem;
    line-height: 96px;
    text-align: center;
    color: #FFFFFF;
}

.landText2 {
    z-index: 2;
    width: 60vw;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
}

.landRegister {
    z-index: 2;
    margin-top: 36px;
    margin-left: 1vw;
    width: 15vw;
    height: 48px;
    background: #FAC949;
    border: none;
    outline: none;
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 24px;
    color: #252525;
    margin-right: 1.5vw;
    cursor: pointer;
    display: none;
}

/* ABOUT US */

.landAbout {
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.aboutScroll {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 48px;
    text-align: center;
    letter-spacing: 0.08em;
    color: #252525;
    /* width: 24vw; */
    padding-bottom: 2px;
    border: 2px solid #252525;
    padding-top: 10px;
    transform: rotate(2deg);
    position: relative;
    z-index: 4;
    overflow: hidden;
}

.aboutFAQ {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 48px;
    text-align: center;
    letter-spacing: 0.08em;
    color: #252525;
    /* width: 24vw; */
    padding-bottom: 2px;
    border: 2px solid #252525;
    padding-top: 10px;
    transform: rotate(2deg);
    position: relative;
    z-index: 4;
    overflow: hidden;
}

#text1Border {
    display: block;
    border: 2px solid #FAC949;
    transform: rotate(-2deg);
    width: 18vw;
    height: 72px;
    position: relative;
    z-index: 2;
    overflow: hidden;
}

#text2Border {
    display: block;
    border: 2px solid #FAC949;
    transform: rotate(-2deg);
    width: 40vw;
    height: 72px;
    position: relative;
    z-index: 2;
    overflow: hidden;
}

.aboutHead {
    width: 41vw;
    margin-top: 24px;
    font-family: 'Bahnschrift';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #252525;
}

.aboutText {
    width: 76vw;
    margin-top: 32px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 0.95rem;
    line-height: 24px;
    text-align: center;
    color: #000000;
    margin-bottom: 40px;
}

/* Domains */

.landDomain {
    background: #F6F6F6;
    /* padding-left: 10vw; */
    padding-top: 50px;
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#domainBorder {
    margin-left: 35vw;
    display: block;
    border: 2px solid #FAC949;
    transform: rotate(-2deg);
    width: 16vw;
    height: 72px;
    z-index: 2;
    overflow: hidden;
}

.domainCards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.domainCard1 {
    width: 22vw;
    height: 250px;
    background: #333333;
    box-shadow: 0px 8px 24px 2px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 3vw;
    margin-left: 3vw;
    margin-top: 30px;
    margin-bottom: 30px;
    cursor: pointer;
    padding-left: 1.2vw;
    padding-right: 1.2vw;
}

.domainLogo1 {
    height: 55px;
    width: 50px;
    margin: 64px auto;
}

.domainText1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    color: #FFFFFF;
    text-align: center;
    margin-top: -16px;
}

.domainCard2 {
    width: 22vw;
    height: 250px;
    background: #FFFFFF;
    box-shadow: 0px 8px 24px 2px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 3vw;
    margin-left: 3vw;
    margin-top: 30px;
    margin-bottom: 30px;
    cursor: pointer;
    padding-left: 1.2vw;
    padding-right: 1.2vw;
}

.landDomain a {
    text-decoration: none;
    color: #000;
}

.domainCard1:hover {
    transform: scale(1.02, 1.02);
    transition: 0.2s;
}

.domainCard2:hover {
    transform: scale(1.02, 1.02);
    transition: 0.2s;
}

.domainCard2 a {
    color: #000;
}

.domainLogo2 {
    height: 60px;
    width: 65px;
    margin: 64px auto;
}

.domainText2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    margin-top: -16px;
    text-align: center;
}

/* FOOTER */

.landFooter {
    width: 100%;
    height: 275px;
    background: #191919;
    padding-top: 56px;
    padding-left: 2vw;
    padding-right: 2vw;
    /* overflow: hidden; */
    overflow-y: hidden;
    /* position: absolute; */
    /* bottom: 0; */
    /* position: fixed; */
}

#footer {
    display: flex;
    justify-content: space-around;
}

#footFlex1 {
    display: flex;
    flex-direction: column;
    margin-top: -4px;
}

.footHead {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 42px;
    color: #FFFFFF;
    margin-bottom: 16px;
}

.footHead2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 1rem;
    line-height: 24px;
    width: 16vw;
    color: #FFFFFF;
    margin-bottom: 8px;
}

#formLink {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 0.9rem;
    color: #FFFFFF;
    margin-top: 0;
}

#formLink>a {
    color: #FFFFFF;
}

#footFlex2 {
    display: flex;
    flex-direction: column;
    gap: 0.4rem
}

#footFlex2Row {
    display: flex;
    padding: 0px;
    gap: 20px;
    margin-left: -3vw;
}

#phone {
    width: 18px;
    height: 18px;
}

#instagram {
    width: 24px;
    height: 24px;
}

#horizon {
    width: 24px;
    height: 24px;
}

#telephone {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    /* line-height: 18px; */
    color: #FAC949;

}

.footFlexRow {
    display: flex;
    padding: 0px;
    gap: 5px;
    /* margin-top: 8px; */
    /* width: 20vw; */
}

.footFlexRow>p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;
}

.footFlexRow a p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;
}

#footFirst {
    margin-top: 36px;
    margin-left: -1.6vw;
}

.footSendicon {
    margin-left: 65vw;
    margin-top: -22px;
}

#footText {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.05em;
    color: #FFFFFF;
    text-align: center;
    position: relative;
    top: 45px;
}

#footText a {
    text-decoration: none;
    color: #FFFFFF;
}

#link {
    cursor: pointer;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;
}

.footFlexRow a {
    color: #FFFFFF;
    text-decoration: none;
}

#link a {
    color: #FFFFFF;
    cursor: pointer;
    text-decoration: underline;
}

#siIcon {
    margin-left: 2px;
    margin-top: -25px;
}

#software {
    text-transform: uppercase;
    color: #2499F9;
    margin-left: 2px;
}

#horizonText {
    text-transform: uppercase;
    color: #FF5800;
    margin-left: 2px;
}

#incubator {
    text-transform: uppercase;
    margin-left: 2px;
}

/* TIMELINE */

.landTimeline {
    padding-bottom: 40px;
    padding-top: 56px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.landScrolls{
    padding-bottom: 40px;
    padding-top: 28px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.landFAQ{
    padding-bottom: 40px;
    padding-top: 28px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.faqContainer{
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:1rem;
    align-items: center;
    width: 80%;

}

#timelineBorder {
    display: block;
    border: 2px solid #FAC949;
    transform: rotate(-2deg);
    height: 72px;
    margin-bottom: 40px;
    width: 16vw;
    margin-left: 40vw;
    z-index: 2;
    overflow: hidden;
}

#processDialog {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    height: fit-content;
    padding-top: 24px;
    padding-bottom: 12px;
    /* width: 30vw; */
}

#alert {
    position: absolute;
    top: 80px;
    right: 0;
    margin-right: 2vw;
    z-index: 999;
     width: 50vw;
     height: fit-content;
}

@media(max-width:2500px) and (min-width:1500px) {
    .landText1 {
        font-size: 5rem;
        line-height: 108px;
    }

    .landText2 {
        font-size: 1.5rem;
        line-height: 40px;
    }

    .landRegister {
        width: 11vw;
        font-size: 1.3rem;
    }

    #text1Border,
    .aboutScroll {
        width: 10vw;
        height: 76px;
    }

    #text2Border,
    .aboutFAQ {
        width: 24vw;
        height: 76px;
    }

    #domainBorder {
        width: 10vw;
    }

    #timelineBorder {
        width: 10vw;
    }

    .aboutHead {
        font-size: 1.5rem;
        line-height: 24px;
    }

    .aboutText {
        font-size: 1.3rem;
        line-height: 30px;
    }

    .domainCard1,
    .domainCard2 {
        width: 22vw;
        height: 380px;
        margin-right: 3vw;
        margin-left: 3vw;
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .domainText1,
    .domainText2 {
        font-size: 1.5rem;
        margin-top: 0;
        margin-bottom: 0;
    }

    .domainLogo1,
    .domainLogo2 {
        width: 85px;
        height: 80px;
    }

    .footHead {
        font-size: 2.2rem;
    }

    .footHead2 {
        font-size: 1.4rem;
        line-height: 32px;
        margin-bottom: 12px;
    }

    #telephone {
        font-size: 1.4rem;
    }

    .footFlexRow a p {
        font-size: 1.15rem;
        line-height: 24px;
    }

    .footFlexRow p,
    #link,
    #formLink {
        font-size: 1.15rem;
        line-height: 24px;
    }

    #footText {
        font-size: 1.1rem;
    }

    .landFooter {
        height: 306px;
    }

    #instagram,
    #horizon {
        width: 28px;
        height: 28px;
    }
}

@media(max-width:1900px) and (min-width:1500px) {


    .accordion-item {
        width: 60vw;
       }
    .landBlock1,
    .landImage,
    #loginBg {
        height: 680px;
    }

    .landRegister {
        width: 13vw;
    }

    #text1Border,
    .aboutScroll {
        width: 15vw;
        font-size: 1.8rem;
    }

    #text2Border,.aboutFAQ{
        width: 32vw;
        font-size: 1.8rem;
    }

    #domainBorder {
        width: 15vw;
    }

    #timelineBorder {
        width: 15vw;
    }

    .landFooter {
        height: 320px;
    }

    .domainCard1,
    .domainCard2 {
        width: 24vw;
    }

    #footText {
        top: 66px;
    }
}

@media(max-width:1800px) {
    #footText {
        top: 45px;
    }
}

@media (max-width:1500px) {
    #loginBg {
        overflow: hidden;
    }

    .accordion-item {
        width: 60vw;
       }

    .landBlock1 {
        overflow: hidden;
    }
}


@media(max-width:1300px) {
    #footText {
        top: 20px;
    }

    #soonDialog {
        width: 34vw;
    }
}

@media(max-width:1200px) {
    .footHead {
        margin-bottom: 12px;
    }

    .accordion-item {
        width: 70vw;

       }

    .footHead2 {
        width: 20vw;
    }

    #footText {
        top: 48px;
    }
}

@media (max-width:1150px) {

    .aboutScroll {
        font-size: 1.4rem;
    }
    .aboutFAQ {
       font-size: 1.4rem;
    }

    .domainCard1,
    .domainCard2 {
        width: 24vw;
        margin-right: 2vw;
        margin-left: 2vw;
    }

    .footIcons {
        margin-left: 72vw;
    }

    .footSendicon {
        margin-left: 60vw;
    }
}

@media(max-width:1050px) {
    .aboutScroll {
        font-size: 1.5rem;
    }

    #text1Border,
    .aboutScroll {
        width: 22vw;
    }

    #text2Border,
    .aboutFAQ {
        width: 46vw;
        height: 76px;
    }

    #domainBorder {
        width: 22vw;
    }

    #timelineBorder {
        width: 22vw;
    }

    .landBlock1,
    .landImage,
    #loginBg {
        height: 600px;
    }

    .landText1,
    .landText2 {
        width: 68vw;
    }

    .landFlex {
        left: 17vw;
    }

    #soonDialog {
        width: 36vw;
    }

    .landRegister {
        width: 16vw;
    }
    #alert{
        width: 30vw;
    }
}

@media(max-width:1030px) {
    #footText {
        top: 28px;
    }

}

@media(max-width:950px) {
    .landRegister {
        width: 18vw;
    }

    .domainCard1,
    .domainCard2 {
        width: 26vw;
        margin-right: 2vw;
        margin-left: 2vw;
        height: 260px;
    }

    #soonDialog {
        width: 38vw;
    }
}

@media(max-width:900px) {

    .landBlock1,
    .landImage,
    #loginBg {
        height: 630px;
    }
}

@media(max-width:850px) {

    .accordion-item {
        width: 80vw;

       }
    .aboutScroll {
        font-size: 1.5rem;
        padding-left: 1vw;
        padding-right: 1vw;
    }

    #text1Border,
    .aboutScroll {
        width: 26vw;
    }

    #text2Border,
    .aboutFAQ {
        width: 56vw;
        height: 76px;
    }

    #domainBorder {
        width: 24vw;
        /* margin-left: 37vw; */
    }

    #timelineBorder {
        width: 24vw;
    }

    .landText1,
    .landText2 {
        width: 70vw;
    }
    #alert{
        width: 35vw;
    }
}

@media(max-width:830px) {

    .landBlock1,
    .landImage,
    #loginBg {
        height: 630px;
    }

    .landText1,
    .landText2 {
        width: 72vw;
    }

    .landFlex {
        left: 14vw;
    }
}

@media(max-width:800px) {
    .aboutHead {
        width: 50vw;

    }

    .aboutText {
        width: 78vw;

    }

    #text1Border,
    .aboutScroll {
        width: 28vw;
    }

    #domainBorder {
        width: 27vw;

    }

    #timelineBorder {
        width: 27vw;

    }

    .domainCard1,
    .domainCard2 {
        width: 34vw;
        margin-right: 3vw;
        margin-left: 3vw;
    }

    .landText1 {
        width: 78vw;
        line-height: 86px;
    }

    .landFlex {
        left: 12vw;
    }

    .landText2 {
        margin-left: 6vw;
        width: 66vw;
    }

    .landRegister {
        width: 22vw;
    }

    .footHead {
        margin-bottom: 8px;
    }

    .footHead2 {
        width: 22vw;
        font-size: 0.95rem;
    }

    .footFlexRow {
        gap: 4px;
    }

    #soonDialog {
        width: 42vw;
    }
}

@media(max-width:770px) {
    .aboutScroll {
        font-size: 1.4rem;
    }

    #telephone {
        font-size: 0.95rem;
    }

    #instagram,
    #horizon {
        width: 20px;
        height: 20px;
    }

    #footFlex2 {
        margin-left: -2vw;
    }

    .landBlock1,
    .landImage,
    #loginBg {
        height: 630px;
    }

}

@media(max-width:700px) {

    .domainCard1,
    .domainCard2 {
        height: 220px;
        margin-top: 20px;
        margin-bottom: 20px;

    }

    .aboutScroll {
        font-size: 1.3rem;
    }
    .aboutFAQ {
        font-size: 1.3rem;
    }

    .aboutHead {
        width: 60vw;

    }

    .footHead {
        font-size: 1.5rem;
        margin-bottom: 4px;
    }

    .footHead2,
    #telephone {
        font-size: 0.9rem;
    }

    #telephone {
        margin-bottom: 8px;
    }

    #footFlex3 {
        margin-left: -2vw;
    }

    #footFlex1 {
        margin-top: -8px;
    }

    #footText {
        top: 40px;
    }

    #soonDialog {
        width: 48vw;
    }

    .landBlock1,
    .landImage,
    #loginBg {
        height: 600px;
    }
    #alert{
        width: 40vw;
    }
}

@media(max-width:700px) {

    .domainCard1,
    .domainCard2 {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .aboutScroll {
        font-size: 1.2rem;
    }

    .aboutFAQ {
        font-size: 1.2rem;
    }

    .aboutHead {
        width: 60vw;

    }

    .landText1 {
        width: 76vw;
        line-height: 80px;
        font-size: 3.6rem;
    }

    .landRegister {
        width: 24vw;
    }

    #footFlex2 {
        margin-left: -3vw;
        gap:0;
    }

    #footFlex3 {
        margin-left: -3vw;
    }

    .footHead {
        font-size: 1.1rem;
        margin-top: -2px;
    }

    .footHead2 {
        font-size: 0.8rem;
    }

    #link,
    #formLink,
    .footFlexRow p,
    .footFlexRow a p {
        font-size: 0.7rem;
        margin-bottom: 8px;
    }

    .landFooter {
        padding-left: 1vw;
        height: 290px;
    }

    #footText {
        top: 54px;
    }
}

@media(max-width:640px) {
    .landText1 {
        width: 80vw;
        font-size: 3.5rem;
    }

    #soonDialog {
        width: 54vw;
    }
}

@media(max-width:600px) {

    .domainCard1,
    .domainCard2 {
        width: 40vw;
        margin-right: 2vw;
        margin-left: 2vw;
    }

    .landText1 {
        width: 86vw;
    }

    .landText2 {
        width: 74vw;
    }

    .landFlex {
        left: 7.4vw;
    }

    .landRegister {
        width: 28vw;
    }

    #text1Border,
    .aboutScroll {
        width: 30vw;
    }

    #text2Border,
    .aboutFAQ {
        width: 64vw;
        height: 76px;
    }

    #timelineBorder {
        width: 30vw;

    }

    #domainBorder {
        width: 30vw;

    }

    #footText {
        top: 44px;
    }

    #footer {
        flex-direction: column;
    }

    .landFooter {
        height: 490px;
        padding-top: 36px;
        padding-left: 8vw;
    }

    .footHead {
        font-size: 1.7rem;
    }

    .footHead2 {
        font-size: 1rem;
        width: 70vw;
    }

    #telephone {
        font-size: 1rem;
        margin-bottom: 12px;
        /* margin-top: 8px; */
    }

    #phone {
        margin-top: 4px;
    }

    #footFlex2,
    #footFlex3,
    #footFlex4 {
        margin-left: 0;
        margin-top: 16px;
    }

    #footFlex2{
        gap: 0;
    }

    #footFlex2Row {
        margin-left: -6vw;
    }

    #soonDialog {
        width: 64vw;
    }

    #footText {
        top: 54px;
        margin-right: 2vw;
        margin-left: -5vw;
        /* margin-left: -8vw; */
    }

    .landingPage {
        padding-bottom: 400px;
    }
}

@media(max-width:580px) {
    .landFooter {
        height: 515px;
        padding-top: 36px;
        padding-left: 8vw;
    }
    #footText {
        top: 32px;
    }
    #alert{
        width: 45vw;
        margin-right: 0;
        right:5vw;
    }
}

@media(max-width:564px) {
    .landRegister {
        width: 30vw;
        margin-left: 0;
    }

    .landBlock1,
    .landImage,
    #loginBg {
        height: 640px;
    }

    .landText1 {
        width: 90vw;
        font-size: 3.4rem;
    }

    .landText2 {
        width: 80vw;
    }

    .landFlex {
        left: 6vw;
    }

    .domainCard1,
    .domainCard2 {
        width: 40vw;
        margin-right: 3vw;
        margin-left: 3vw;
    }

    #text1Border,
    .aboutScroll {
        width: 32vw;
    }

    #text2Border,
    .aboutFAQ {
        width: 69vw;
        height: 76px;
    }

    #domainBorder,
    #timelineBorder {
        width: 32vw;
    }

    #soonDialog {
        width: 60vw;
    }
}

@media(max-width:540px) {
    #footText {
        top: 12px;
    }
}

@media(max-width:520px) {

    .landBlock1,
    .landImage,
    #loginBg {
        height: 670px;
    }
}

@media(max-width:505px) {
    .landRegister {
        width: 30vw;
        margin-left: 2vw;
    }

    .landText1 {
        width: 90vw;
        font-size: 3.2rem;
    }

    .landText2 {
        width: 78vw;
    }

    .landFlex {
        left: 5vw;
    }

    .domainCard1,
    .domainCard2 {
        height: 240px;
    }

    .domainLogo1,
    .domainLogo2 {
        margin-bottom: 40px;
    }

    #text1Border,
    .aboutScroll {
        width: 38vw;
        font-size: 1.3rem;
    }

    #text2Border,
    .aboutFAQ {
        width: 76vw;
        height: 76px;
    }

    #domainBorder {
        width: 38vw;

    }

    #timelineBorder {
        width: 38vw;
    }
    #alert{
        width: 55vw;
    }
}

@media(max-width:480px) {

    .landBlock1,
    .landImage,
    #loginBg {
        height: 665px;
    }

    .landText1 {
        width: 94vw;
    }

    .landFlex {
        left: 4vw;
    }

    #text1Border,
    .aboutScroll {
        width: 42vw;
        font-size: 1.3rem;
    }

    .landRegister {
        width: 34vw;
        margin-top: 16px;
    }


    #domainBorder {
        width: 42vw;

    }

    #timelineBorder {
        width: 42vw;
    }

    .domainCard1,
    .domainCard2 {
        width: 70vw;
        height: 240px;
        margin-right: 4vw;
        margin-left: 4vw;
    }

    #soonDialog {
        width: 70vw;
    }
}

@media(max-width:450px) {

    .landBlock1,
    .landImage,
    #loginBg {
        height: 680px;
    }

    #soonDialog {
        width: 75vw;
    }

    .landFooter {
        height: 515px;
    }

    #footText {
        top: 28px;
    }

}

@media(max-width:425px) {
    .landText1 {
        font-size: 3rem;
        line-height: 70px;
    }

    #domainBorder,
    #timelineBorder {
        width: 44vw;

    }

    #text1Border,
    .aboutScroll {
        width: 44vw;
        font-size: 1.3rem;
    }
}

@media(max-width:400px) {
    .landRegister {
        width: 40vw;
    }

    #domainBorder {
        width: 48vw;
    }

    #timelineBorder {
        width: 48vw;

    }

    #text1Border,
    .aboutScroll {
        width: 48vw;
        font-size: 1.3rem;
    }

    .footIcons {
        margin-left: 16vw;
    }

    .footIconFlex2 {
        margin-left: 10vw;
    }

    .landText1 {
        font-size: 3rem;
    }
    #alert{
        width: 65vw;
    }
}

@media(max-width:360px) {

    #text1Border,
    .aboutScroll {
        width: 50vw;
        font-size: 1.25rem;
    }
    #alert{
        width: 65vw;
    }
}

@media(max-width:330px) {
    .landText1 {
        width: 90vw;
        font-size: 2rem;
    }

    .landText2 {
        width: 80vw;
    }

    .landFlex {
        left: 4vw;

    }

    .landRegister {
        width: 52vw;
    }

    #domainBorder,
    #timelineBorder,
    #text1Border {
        width: 52vw;

    }

    #text1Border,
    .aboutScroll {
        width: 52vw;
        font-size: 1.2rem;
    }

    .landFooter {
        height: 515px;
    }

    #footText {
        top: 35px;
    }
}

@media(max-width:300px) {
    .landFooter {
        height: 580px;
    }

    #text1Border,
    .aboutScroll {
        width: 56vw;
        font-size: 1.1rem;
        height: 64px;
    }
}

.slick-slider.slick-initialized {
    width: 100%;
    position: relative;
}
.slick-next::before {
    display: none;
    }
button.slick-next {
    height:10rem;
    width:4rem;
    background: url(/src/Components/Assets/arrow2.svg);
background-position: center center!important;
background-repeat: no-repeat!important;
background-size: contain!important;
z-index:1;
position: absolute;
right: 5%;
}
button.slick-next:focus {
    height:10rem;
    width:4rem;
    background: url(/src/Components/Assets/arrow2.svg);
background-position: center center!important;
background-repeat: no-repeat!important;
background-size: contain!important;
z-index:1;
position: absolute;
right: 5%;
}
button.slick-next:hover {
    height:10rem;
    width:4rem;
    background: url(/src/Components/Assets/arrow2.svg);
background-position: center center!important;
background-repeat: no-repeat!important;
background-size: contain!important;
z-index:1;
position: absolute;
right: 5%;
}
    button.slick-prev {
        height:10rem;
        width:4rem;
        background: url(/src/Components/Assets/arrow1.svg);
background-position: center center!important;
background-repeat: no-repeat!important;
background-size: contain !important;
z-index:1;
position: absolute;
left: 5%;
      }
      button.slick-prev:hover {
        height:10rem;
        width:4rem;
        background: url(/src/Components/Assets/arrow1.svg);
background-position: center center!important;
background-repeat: no-repeat!important;
background-size: contain !important;
z-index:1;
position: absolute;
left: 5%;
      }
      button.slick-prev:focus {
        height:10rem;
        width:4rem;
        background: url(/src/Components/Assets/arrow1.svg);
background-position: center center!important;
background-repeat: no-repeat!important;
background-size: contain !important;
z-index:1;
position: absolute;
left: 5%;
      }
        .slick-prev::before {
        display: none;
        }

        .sliderItem {
            font-size: 40px;
            display: flex;
            justify-content: center;
            border-radius: 0.5rem;
            margin: 0px auto;
            font-family: sans-serif;
            width: 75%;
            height:75%;
            box-sizing: border-box;
            margin: 0px 12.5%;
          }

          .questionBox{
            display: flex;
            height: 4.5rem;
            width: 64vw;
            align-items: center;
            justify-content: space-between;
            border: 1px solid black;
            padding: 0 1rem;
            border-radius: 0.25rem;
            font-weight: 400;
          }

          .accordion-header {
            border: none;
            background: white !important;

        }
        .accordion-button{
            background: white !important;
            color: black !important;
            border-bottom: 1px solid #000000 !important;

        }
       
        .accordion-button:not(.collapsed)::after {
            filter: invert(50%);
        }

        .winner23{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 2rem 0;
        }
        .winnerImg{
            width: 30rem;
            height: 25rem;
            border-radius: 0.5rem;
        }
        .winnerText{
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 1rem;
            width: 50%;
        }
        .winnerHead{
            font-size: 3rem;
            font-weight: 600;
        }
        .winImg{
            position: relative;
            padding-right: 2rem;
            padding-top: 2rem;
            padding-left: 2rem;
        }
        .winnerImg1{
            position: absolute;
            right: -5%;
            z-index: 1;
            top:-5%;
        }

@media(max-width:1087px) {
    .winnerHead{
        font-size: 2rem;
        font-weight: 600;
    }
    .winnerText
    {
        width:80%;
    }
    .winner23{
        gap:1.5rem;
    }
    button.slick-prev {
        scale: 85%;}
    button.slick-next {
        scale: 85%;}

}
@media(max-width:900px) {
    .sliderItem{
        height:90%;
    }
    
    button.slick-prev {
        scale: 80%;}
    button.slick-next {
        scale: 80%;}

}
@media(max-width:700px) {
    .sliderItem{
        height:90%;
    }
    .winnerText
    {
        width:84%;
        text-align: center;
    }
    .winnerImg{
        width: 21rem;
        height: 18rem;
        border-radius: 0.5rem;
    }
    .winnerImg1{

    scale:90%;}

    button.slick-prev {
        scale: 75%;
        top:45%;

    }
    button.slick-next {
        scale: 75%;
        top:45%;
    }

}

@media(max-width:500px) {
    button.slick-prev {
        scale: 70%;
        top:40%;

    }
    button.slick-next {
        scale: 70%;
        top:40%;
    }
    button.slick-next,button.slick-prev{
        
        margin: 0px -5%;
    }
   


}

    
       
    
    
      