.Domain {
    display: flex;
    flex-direction: column;
    padding: 116px 8vw 40px 6vw;
}

.domainHead {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 2rem;
    color: #FAC949;
    margin-bottom: 20px;
    text-transform: capitalize;
}

.Domain ul {
    padding-left: 0;
}

.Domain ul li {
    list-style: none;
    margin-bottom: 32px;
}

.domainHeading {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 1.2rem;
    color: rgba(0, 0, 0, 0.9);
    margin-bottom: 8px;
}

.domainText {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.6;
    color: #000000;
    /* margin-bottom: 24px; */
}

@media (min-width: 1500px) and (max-width: 2500px) {
    .domainHeading {
        font-size: 1.6rem;
    }

    .domainText {
        font-size: 1.4rem;
        line-height: 1.8;
    }

    .domainHead {
        font-size: 2.5rem;
    }

    .Domain {
        /* margin-bottom: 280px; */
    }
}

@media (max-width: 600px) {
    .Domain {
        padding: 116px 6vw 40px 5vw; /* Adjusting padding */
    }
}
