.logoutDiv {
    display: flex;
    /* position:absolute; */
    /* top:20vh; */
    /* left:29vw; */
    width: 40vw;
    height: 320px;
    background-color: #FFFFFF;
    padding-left: 2rem;
    padding-top: 40px;
    box-shadow: 0px 50px 80px #1A1C1E;
    z-index: 110;
}

.lOut1 {
    display: flex;
    flex-direction: column;
}

.logoutText1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
}

.logoutText2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
}

.logoutbtn1 {
    width: 16vw;
    height: 38px;
    background: #FAC949;
    border-radius: 8px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    border: none;
    margin-bottom: 12px;
    margin-top: 25px;
    text-align: center;
    color: #FFFFFF;
}

.logoutbtn2 {
    box-sizing: border-box;
    width: 16vw;
    height: 38px;
    background: #F2F2F2;
    border: none;
    border-radius: 8px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FAC949;
}

.logoutImage {
    width: 240px;
    height: 200px;
    margin-top: 100px;
    margin-left: 0vw;
}

@media(max-width:2500px) and (min-width:1500px) {

    .logoutbtn1,
    .logoutbtn2 {
        width: 9vw;
    }

    .lOut2 {
        margin-left: -1.5vw;
    }

    .logoutImage {
        width: 260px;
        height: 260px;
        margin-top: 65px;
    }

    .logoutText1 {
        font-size: 1.4rem;
    }

    .logoutText2 {
        font-size: 1.2rem;
    }
}

@media(max-width:2020px) and (min-width:1500px) {

    .logoutbtn1,
    .logoutbtn2 {
        width: 11vw;
    }
}

@media(max-width:2020px) and (min-width:1400px) {
    .logoutImage {
        width: 280px;
        height: 280px;
        margin-top: 50px;
    }

    .lOut2 {
        margin-left: -2vw;
    }

    .logoutbtn1,
    .logoutbtn2 {
        width: 12vw;
    }
}

@media(max-width:1500px) and (min-width:1400px) {
    .lOut2 {
        margin-left: 0vw;
    }
}

@media (max-width:1350px) {

    /* .logoutImage {
        width: 200px;
        margin-left: 0vw;
    } */
    .lOut2 {
        margin-left: -1vw;
    }
}

@media (max-width:1290px) {
    .lOut2 {
        margin-left: -2vw;
    }

    .logoutbtn1,
    .logoutbtn2 {
        width: 16vw;
    }
    .logoutDiv {
    padding-left: 1.5rem;

    }
}

@media (max-width:1200px) {
    .lOut2 {
        margin-left: -4vw;
    }
}

@media (max-width:1100px) {
    .lOut2 {
        margin-left: -6vw;
    }
}

@media (max-width:1000px) {
    .lOut2 {
        margin-left: -8vw;
    }

    .logoutDiv {
        width: 44vw;
    }
}


@media (max-width:900px) {
    .lOut2 {
        margin-left: -12vw;
    }
}


@media (max-width:800px) {
    .lOut2 {
        margin-left: -14vw;
    }

    .logoutDiv {
        width: 48vw;
    padding-left: 1.5rem;

    }

    .logoutbtn1,
    .logoutbtn2 {
        width: 17vw;
    }
}

@media (max-width:700px) {
    .lOut2 {
        margin-left: -16vw;
    }

    .logoutDiv {
        width: 60vw;
    }

    .logoutbtn1,
    .logoutbtn2 {
        width: 22vw;
    }
}

@media (max-width:600px) {
    .lOut2 {
        margin-left: -18vw;
    }

    .logoutDiv {
        width: 68vw;
    }

    .logoutbtn1,
    .logoutbtn2 {
        width: 24vw;
    }
    .logoutDiv {
        padding-left: 1rem;
    
        }
}

@media (max-width:500px) {
    .lOut2 {
        margin-left: -28vw;
    }

    .logoutDiv {
        width: 80vw;
    }

    .logoutbtn1,
    .logoutbtn2 {
        width: 30vw;
    }
}

@media (max-width:400px) {
    .logoutDiv {
        width: 90vw;
        height: 300px;
    }

    .logoutbtn1,
    .logoutbtn2 {
        width: 34vw;
    }

    .logoutImage {
        width: 220px;
        height: 180px;

    }

    .lOut2 {
        margin-left: -35vw;
    }
}

@media(max-width:360px) {
    .logoutImage {
        width: 190px;
        height: 170px;
        margin-top: 114px;
    }

    .lOut2 {
        margin-left: -40vw;
    }
}