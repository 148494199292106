#RESULT {
    display: flex;
    flex-direction: column;
    padding: 100px 6vw 40px 6vw;
    /* margin-bottom: 240px; */
}

#resultHead {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 1.8rem;
    color: #FAC949;
    margin-bottom: 20px;
    text-transform: capitalize;
}

.resDiv {
    border: 2px solid #D9D9D9;
    padding: 16px 2vw;
    border-radius: 8px;
    margin-bottom: 32px;
}

.resHead {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 1.3rem;
    text-decoration: underline;
}

.resHead2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 1.12rem;
    /* text-decoration: underline; */
    text-align: center;
    color: rgba(0, 0, 0, 0.9);
    margin: 4px;
}

.resTable {
    width: 100%;
}

.resTableRow {
    text-align: center;
}

.resTableRow>td {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    color: #000;
    white-space: pre-wrap;
    word-wrap: break-word;
    border: 0.5px solid #D9D9D9;
    padding: 6px;
    text-align: center;
    margin-bottom: 0;
}

thead>tr>th {
    text-align: center;
}

#tableRow>td {
    padding: 4px;
    font-size: 0.9rem;
}

.resTableRow td ul {
    text-align: left;
    padding-left: 0;
    padding-top: 8px;
}

.resTableRow td ul li {
    list-style-position: inside;
    line-height: 20px;
}

.resTableRow td ul li p {
    display: block;
    margin-left: 16px;
    margin-top: -20px;
}

@media (max-width: 1000px) {
    #RESULT {
        padding-right: 3vw;
        padding-left: 3vw;
    }

}

@media (max-width: 600px) {
    .resDiv {
        padding-left: 1.2rem;
        padding-right: 1.2rem;}
    #RESULT {
        /* margin-bottom: 480px; */
        /* overflow: scroll; */
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .resTablee {
        width: 130vw;
    }

    .resHead {
        font-size: 1.1rem;
    }

    #resultHead {
        font-size: 1.5rem;
    }
    
}

@media (max-width: 400px) {
    #RESULT {
        /* margin-bottom: 480px; */
        /* overflow: scroll; */
        padding-left: 1.2rem;
        padding-right: 1.2rem;
    }
    .resTablee {
        width: 140vw;
    }
}