#error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 120px;
    padding-bottom: 160px;
    margin-bottom: 120px;
}

.error {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    text-align: center;
    margin-top: 5vh;
}

.errorHead {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
    color: #FAC949
}

.errorText {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    text-align: center;
}

.errorText a {
    color: #FAC949;
    cursor: pointer;
    text-decoration: none;
}

#errorImage {
    width: 350px;
    height: 300px;
    margin-bottom: 24px;
}


@media(max-width:2500px) and (min-width:1500px) {
    .errorHead {
        font-size: 3rem;
        margin-top: 8px;
    }
}

@media(max-width:700px) {
    .errorHead {
        margin-top: 0;
    }

    #errorImage {
        margin-bottom: 0px;
    }
}

@media(max-width:500px) {
    #errorImage {
        width: 300px;
        height: 250px;
    }

    .errorHead {
        font-size: 1.6rem;
    }

    #error {
        padding-bottom: 300px;
        margin-bottom: 280px;
        padding-right:2vw;
        padding-left:2vw;
    }
}