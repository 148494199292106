#registerAs {
    margin-left: 5vw;
    margin-right: 5vw;
}



#regDiv {
    height: 500px;
}

.asRegister {
    width: 25vw;
    height: 50px;
    background: none;
    outline: none;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    margin-left: 7.2vw;
    padding-left: 1vw;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 20px;
    margin-top: 24px;
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
}

.asRegister:hover {
    border: 1px solid #FAC949;
    color: #FAC949;
}

#registerCA {
    font-size: 1.5rem;
    margin-right: 1vw;
    margin-left: 1vw;
}

#regMember {
    margin-top: 48px;
    margin-bottom: 12px;

}

#CA {
    margin-top: 36px;
}

.regContinue {
    width: 25vw;
    height: 48px;
    background: #FAC949;
    border-radius: 5px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #252525;
    /* margin-left: 7vw; */
    border: none;
    outline: none;
    margin-top: 32px;
    cursor: pointer;
}

.register {
    display: flex;
    flex-direction: column;
    width: 40vw;
    height: 1150px;
    /* height:1400px; */
    /* height: 1000px; */
    padding-top: 2rem;
    background: #FFFFFF;
    border-radius: 8px;
    padding-top: 4px;
    z-index: 5;
    /* background-color: aqua; */
    margin: auto;
    justify-content: center;
}
#registerTeam {
    display: flex;
    flex-direction: column;
    /* width: 40vw; */
    /* height: 950px; */
    /* height:1400px; */
    height: 1000px;
    background: #FFFFFF;
    border-radius: 8px;
    padding-top: 4px;
    z-index: 5;
    /* background-color: aqua; */
    margin: auto;
    justify-content: center;
    padding-top: 4rem;
}

.regFlex {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-left: 1vw;
    margin-top: -80px;
    margin-right: 1vw;
}

.loginFlex {
    margin-top: -40px;
    margin-bottom: 4px;
}

#loginHeadbox {
    margin-top: -60px;
    margin-bottom: 15px;
}

#fgtHeadbox {
    margin-top: 0px;
    margin-bottom: 10px;
}

#rstHeadbox {
    margin-top: -30px;
    margin-bottom: 15px;
}

#regCA {
    margin-top: -100px;
    margin-bottom: 15px;
}

#LoginOpt {
    margin-top: -120px;
}

#memberReg {
    margin-top: 10px;
    margin-bottom: 10px;
}

#teamReg {
    margin-bottom: 15px;
}

.cross {
    margin-right: 3vw;
    margin-left: 3vw;
    width: 18px;
    cursor: pointer;
}
#crs{
    position: absolute;
right: 0;
margin-top: 0.25rem;
margin-right: 3%;

}

.arrow {
    margin-left: 3vw;
    margin-right: 3vw;
    width: 18px;
    cursor: pointer;
}

#back {
    width: 28px;
}

.heading {
    display: inline;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 48px;
     /* padding-top: 4px; */
    text-align: center;
    letter-spacing: 0.08em;
    color: #252525;
    margin-bottom: 0;
}

#member {
    color: #FAC949;
}

.regName {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    /* line-height: 2px; */
    color: #000000;
    /* padding-top: 16px; */
    padding-bottom: 4px;
    padding-top: 2px;
    margin-bottom: 0.25rem;
    margin-top: 0.75rem;
    overflow-y: hidden;
}


.regInputname {
    width: 25vw;
    height: 48px;
    background: none;
    outline: none;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    padding: 0 0.5rem;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    height: 48px;
    outline:none !important;
}

.genders {
    display: flex;
    padding-bottom: 0.5rem;
    overflow: hidden;
}

.regGender {
    width: 7.5vw;
    margin-right: 1vw;
    height: 42px;
    background: none;
    outline: none;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
}

.regGender:hover {
    border: 1px solid #FAC949;
    color: #FAC949;
}

::placeholder {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.5);
}

#otherOption,
#otherCA {
    display: none;
    width: 25vw;
    background: none;
    outline: none;
    border: none;
    /* margin-left: 7vw; */
    padding-left: 1vw;
    border-bottom: 1px solid #D9D9D9;
    margin-top: 12px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
}

#option {
    width: 25vw;
    /* height: 54px;
    background-color: #FAC949; */
}

#recaptcha {
    margin-left: 1.5vw;
    margin-top: 20px;
    padding: 10px 0;
}

#recaptcha iframe
{
     min-width: 300px;
    transform: scale(0.77);
        -webkit-transform: scale(0.77);
        transform-origin: 0 0;
        -webkit-transform-origin: 0 0; 
        /* width: 100% !important; */
 }

/* @media only screen and (max-width:500px){
    #recaptcha *{
        transform: scale(0.77);
        -webkit-transform: scale(0.77);
        transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
    }
} */



#wrongName,
#wrongEmail,
#WrongPwd1,
#WrongPwd2,
#wrongBranch,
#wrongNum,
#WrongPwdTeam1,
#WrongPwdTeam2,
#wrongCAEmail,
#wrongNameCA,
#wrongEmailCA,
#WrongPwdCA1,
#WrongPwdCA2,
#wrongBranchCA,
#wrongNumCA,
#wrongEmailLog1,
#wrongPwdLog1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 16px;
    color: #B00020;
    margin-top: 2px;
    width: 25vw;
    display: none;
    margin-bottom: 0px;
    padding-top: 2px;
    padding-bottom: 6px;
    overflow: hidden;

}

.teamError {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 16px;
    color: #B00020;
    margin-top: 4px;
    width: 26vw;
    margin-bottom: 4px;
}

#LEye,
#TEye,
#CEye {
    position: relative;
    /* top:1.5vw; */
    top: 15px;
    left: 23vw;
    cursor: pointer;
    width: 18px;
    height: 18px;
    /* margin-top: -48px; */
}

.inputPwd {
    /* position */
    margin-top: -16px;
}

.regButton {
    width: 25vw;
    height: 48px;
    background: #FAC949;
    border-radius: 5px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #252525;
    border: none;
    outline: none;
    margin-top: 32px;
    cursor: pointer;
    margin-bottom: 32px;
}

.forgotPass {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #252525;
    text-decoration: underline;
    padding-top: 14px;
    cursor: pointer;
    position: absolute;
    right: 6vw;
    top: 300px;
}
@media(max-width: 900px) {
    .forgotPass {
    padding-top: 12px;
        
    }}
@media(max-width: 495px) {
    .forgotPass {
    padding-top: 8px;
        
    }}
    @media(max-width: 450px) {
        .forgotPass {
        padding-top: 15px;
            
        }
    
}

#caFgtpass {
    top: 328px;
}
@media(max-width: 450px) {
    #caFgtpass {
    padding-top: 8px;
        
    }

}
@media(max-width: 378px) {
    #caFgtpass {
    padding-top: 26px;
        
    }

}
@media(max-width: 360px) {
    #caFgtpass {
    padding-top: 8px;
        
    }

}

.forgotText {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.05em;
    /* margin-left: 7vw; */
    color: #252525;
    width: 25vw;
    margin-bottom: 8px;
    margin-top: 8px;
}

.otpInputFlex {
    display: flex;
    /* margin-left: 3.5vw; */
}

.otpInput {
    width: 3vw;
    height: 36px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    margin-bottom: 4px;
    margin-right: 1vw;
    padding: 4px 4px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 1rem;
    line-height: 18px;
    margin-top: 16px;
    color: rgba(0, 0, 0, 0.9);
}

.resend {
    display: flex;
    /* margin-left: 7vw; */
    margin-top: 8px;
}

#resendOtp {
    cursor: pointer;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 20px;
    letter-spacing: 0.05em;
    color: #000000;
    margin-right: 1vw;
}

#timer {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 20px;
    letter-spacing: 0.05em;
    color: #000000;
}

.allForm {
    margin-left: 7.2vw;
    display: flex;
    flex-direction: column;
}

@media(max-width:2500px) and (min-width:1500px) {

    .asRegister,
    .regInputname,
    .regButton,
    #otherOption,
    #otherCA,
    .regContinue {
        width: 21vw;
        /* height: 54px; */
    }
    #registerTeam{
        padding-top: 5.8rem;
        /* height: 1100px !important; */
    }

    .allForm {
        margin-left: 5vw;
    }

    .register {
        width: 32vw;
    }

    .regGender {
        width: 6.2vw;
    }

    #LEye,
    #TEye,
    #CEye {
        left: 19vw;
    }

    #registerAs {
        margin-left: 0;
        margin-right: 0;
    }

    .asRegister {
        margin-left: 5vw;
    }

    .forgotText {
        width: 20vw;
    }

    #wrongName,
    #wrongEmail,
    #WrongPwd1,
    #WrongPwd2,
    #wrongBranch,
    #wrongNum,
    #WrongPwdTeam1,
    #WrongPwdTeam2,
    #wrongCAEmail,
    #wrongNameCA,
    #wrongEmailCA,
    #WrongPwdCA1,
    #WrongPwdCA2,
    #wrongBranchCA,
    #wrongNumCA,
    #wrongEmailLog1,
    #wrongPwdLog1,
    .teamError {
        width: 21vw;
        margin-bottom: 4px;
    }

    #recaptcha {
        margin-left: 4vw;
    }

    .forgotPass {
        /* margin-left: 15vw; */
        position: absolute;
        right: 6vw;
        top: 280px;
        margin-bottom: 8px;
    }

    #caFgtpass {
        top: 328px;
    }

    #registerCA {
        margin-right: 0;
        margin-left: 0;
    }
}


@media(max-width:2000px) and (min-width:1500px) {
    #recaptcha {
        margin-left: 3vw;
    }

    #wrongName,
    #wrongEmail,
    #WrongPwd1,
    #WrongPwd2,
    #wrongBranch,
    #wrongNum,
    #WrongPwdTeam1,
    #WrongPwdTeam2,
    #wrongCAEmail,
    #wrongNameCA,
    #wrongEmailCA,
    #WrongPwdCA1,
    #WrongPwdCA2,
    #wrongBranchCA,
    #wrongNumCA,
    #wrongEmailLog1,
    #wrongPwdLog1 {
        line-height: 17px;
    }
}

@media(max-width:1900px) {
    #recaptcha {
        margin-left: 2vw;
    }
}

@media(max-width:1700px) {
    #recaptcha {
        margin-left: 1vw;
    }

    #wrongName,
    #wrongEmail,
    #WrongPwd1,
    #WrongPwd2,
    #wrongBranch,
    #wrongNum,
    #WrongPwdTeam1,
    #WrongPwdTeam2,
    #wrongCAEmail,
    #wrongNameCA,
    #wrongEmailCA,
    #WrongPwdCA1,
    #WrongPwdCA2,
    #wrongBranchCA,
    #wrongNumCA,
    #wrongEmailLog1,
    #wrongPwdLog1,
    .teamError {
        line-height: 16px;
    }
}

@media(max-width:1500px) {

    .forgotPass {
        right: 8vw;
        top: 298px;
    }
}

@media (max-width:1310px) {
    .register {
        width: 44vw;
    }

    .asRegister,
    .regInputname,
    .regButton,
    #otherOption,
    #otherCA,
    .regContinue {
        width: 28vw;
    }

    .regGender {
        height: 46px;
        width: 8.5vw;
    }

    #wrongName,
    #wrongEmail,
    #WrongPwd1,
    #WrongPwd2,
    #wrongBranch,
    #wrongNum,
    #WrongPwdTeam1,
    #WrongPwdTeam2,
    #wrongCAEmail,
    #wrongNameCA,
    #wrongEmailCA,
    #WrongPwdCA1,
    #WrongPwdCA2,
    #wrongBranchCA,
    #wrongNumCA,
    #wrongEmailLog1,
    #wrongPwdLog1,
    .forgotText,
    .teamError {
        width: 28vw;
        font-size: 0.85rem;
    }

    #LEye,
    #TEye,
    #CEye {
        left: 25.5vw;
    }

    .forgotPass {
        right: 9vw;
    }

}

@media(max-width:1220px) {

    .forgotPass {
        right: 9.4vw;
    }
}

@media(max-width:1150px) {

    .heading {
        font-size: 1.6rem;
    }

    #registerAs {
        font-size: 1.8rem;
    }

    #wrongName,
    #wrongEmail,
    #WrongPwd1,
    #WrongPwd2,
    #wrongBranch,
    #wrongNum,
    #WrongPwdTeam1,
    #WrongPwdTeam2,
    #wrongCAEmail,
    #wrongNameCA,
    #wrongEmailCA,
    #WrongPwdCA1,
    #WrongPwdCA2,
    #wrongBranchCA,
    #wrongNumCA,
    #wrongEmailLog1,
    #wrongPwdLog1,
    .forgotText,
    .teamError {
        width: 28vw;
        font-size: 0.8rem;
    }

    .register {
        width: 46vw;
    }

    .asRegister,
    .regInputname,
    .regButton,
    #otherOption,
    #otherCA,
    .regContinue {
        width: 30vw;
    }

    .regGender {
        width: 9vw;
    }

    #LEye,
    #TEye,
    #CEye {
        left: 27.2vw;
    }

    .otpInput {
        width: 4vw;
        margin-right: 1.5vw;
    }
}

@media(max-width:1100px) {
    #recaptcha {
        margin-left: 1vw;
    }
}

@media(max-width:1080px) {
    #registerCA {
        margin-left: 0;
        margin-right: 0;
    }
}

@media (max-width:1050px) {
    .register {
        width: 48vw;

    }

    .asRegister,
    .regInputname,
    .regButton,
    #otherOption,
    #otherCA,
    .regContinue,
    .forgotText {
        width: 32vw;
    }

    #wrongName,
    #wrongEmail,
    #WrongPwd1,
    #WrongPwd2,
    #wrongBranch,
    #wrongNum,
    #WrongPwdTeam1,
    #WrongPwdTeam2,
    #wrongCAEmail,
    #wrongNameCA,
    #wrongEmailCA,
    #WrongPwdCA1,
    #WrongPwdCA2,
    #wrongBranchCA,
    #wrongNumCA,
    #wrongEmailLog1,
    #wrongPwdLog1,
    .teamError {
        width: 32vw;
    }

    #LEye,
    #TEye,
    #CEye {
        left: 29vw;
    }

    .regGender {
        width: 9.5vw;
    }
}

@media(max-width:1000px) {
    #recaptcha {
        margin-left: 0vw;
    }

    .forgotPass {
        top: 296px;
    }
}

@media (max-width:950px) {
    .register {
        width: 50vw;
        padding-top: 8px;
    }

    .asRegister,
    .regInputname,
    .regButton,
    #otherOption,
    #otherCA,
    .regContinue,
    .forgotText {
        width: 34vw;
        height: 46px;
    }

    #LEye,
    #TEye,
    #CEye {
        left: 31vw;
    }

    .regGender {
        width: 10vw;
    }

    .regName,
    #wrongName,
    #wrongEmail,
    #WrongPwd1,
    #WrongPwd2,
    #wrongBranch,
    #wrongNum,
    #WrongPwdTeam1,
    #WrongPwdTeam2,
    #wrongCAEmail,
    #wrongNameCA,
    #wrongEmailCA,
    #WrongPwdCA1,
    #WrongPwdCA2,
    #wrongBranchCA,
    #wrongNumCA,
    #wrongEmailLog1,
    #wrongPwdLog1,
    .teamError {
        width: 33vw;
        font-size: 0.85rem;
    }

    .otpInput {
        width: 5vw;
        margin-right: 1.5vw;
    }

    #registerCA {
        margin-left: 0.5vw;
        margin-right: 0.5vw;
    }

    #regCA {
        margin-right: 1vw;
    }

    /* #teamReg {
        margin-top: -90px;
    } */
}

@media(max-width:910px) {
    #memberReg {
        margin-top: 4px;
    }

    .allForm {
        margin-left: 7.6vw;
    }

    .forgotPass {
        top: 298px;
    }

    .asRegister {
        margin-left: 8.1vw;
    }
}

@media (max-width:870px) {
    .register {
        width: 56vw;
    }

    .regInputname,
    .regButton,
    #otherOption,
    #otherCA,
    .asRegister,
    .regContinue,
    .forgotText {
        width: 38.5vw;
        /* margin-left: 8.3vw; */
    }

    .regName,
    #wrongName,
    #wrongEmail,
    #WrongPwd1,
    #WrongPwd2,
    #wrongBranch,
    #wrongNum,
    #WrongPwdTeam1,
    #WrongPwdTeam2,
    #wrongCAEmail,
    #wrongNameCA,
    #wrongEmailCA,
    #WrongPwdCA1,
    #WrongPwdCA2,
    #wrongBranchCA,
    #wrongNumCA,
    #wrongEmailLog1,
    #wrongPwdLog1,
    .teamError {
        
        width: 36vw;
    }

    #LEye,
    #TEye,
    #CEye {
        left: 35vw;
    }

    .regGender {
        width: 12vw;
    }

    #teamForm {
        margin-left: -9vw;
    }

    #recaptcha {
        margin-left: 2vw;
    }
}


@media (max-width:800px) {
    .register {
        width: 62vw;
    }

    .asRegister,
    .regInputname,
    .regButton,
    #otherOption,
    #otherCA,
    .regContinue,
    .forgotText {
        width: 42vw;
    }

    .asRegister {
        margin-left: 9vw;
    }

    .regName,
    #wrongName,
    #wrongEmail,
    #WrongPwd1,
    #WrongPwd2,
    #wrongBranch,
    #wrongNum,
    #WrongPwdTeam1,
    #WrongPwdTeam2,
    #wrongCAEmail,
    #wrongNameCA,
    #wrongEmailCA,
    #WrongPwdCA1,
    #WrongPwdCA2,
    #wrongBranchCA,
    #wrongNumCA,
    #wrongEmailLog1,
    #wrongPwdLog1,
    .teamError {
        width: 40vw;
        /* margin-left:8.8vw; */
    }

    #LEye,
    #TEye,
    #CEye {
        left: 38.5vw;
    }

    .regGender {
        width: 13vw;
    }

    .allForm {
        margin-left: 9vw;
    }

    .forgotPass {
        right: 11vw;
    }
}

@media(max-width:750px) {
    #recaptcha {
        margin-left: 0vw;
    }
}

@media (max-width:720px) {
    .register {
        width: 64vw;
    }

    .asRegister,
    .regInputname,
    .regButton,
    #otherOption,
    #otherCA,
    .regContinue,
    .forgotText {
        width: 45vw;
    }

    .regName,
    #wrongName,
    #wrongEmail,
    #WrongPwd1,
    #WrongPwd2,
    #wrongBranch,
    #wrongNum,
    #WrongPwdTeam1,
    #WrongPwdTeam2,
    #wrongCAEmail,
    #wrongNameCA,
    #wrongEmailCA,
    #WrongPwdCA1,
    #WrongPwdCA2,
    #wrongBranchCA,
    #wrongNumCA,
    #wrongEmailLog1,
    #wrongPwdLog1,
    .teamError {
        width: 44vw;
    }

    #LEye,
    #TEye,
    #CEye {
        left: 41vw;
    }

    .regGender {
        width: 13.5vw;
        margin-right: 2vw;
    }

    .heading {
        font-size: 1.5rem;
    }

    #registerAs {
        font-size: 1.8rem;
    }

    #recaptcha {
        margin-left: 0vw;
    }

    .otpInput {
        width: 6vw;
    }
}

@media(max-width:690px) {
    #recaptcha {
        margin-left: 0vw;
    }
}

@media(max-width:670px) {

    #registerCA {
        margin-left: 0vw;
        margin-right: 0vw;
    }
}

@media (max-width:630px) {

    .register {
        width: 68vw;
    }

    .asRegister,
    .regInputname,
    .regButton,
    #otherOption,
    #otherCA,
    .regContinue,
    .forgotText {
        width: 49vw;
    }

    #LEye,
    #TEye,
    #CEye {
        left: 44vw;
    }

    .regGender {
        width: 14.5vw;
    }

    .regName,
    #wrongName,
    #wrongEmail,
    #WrongPwd1,
    #WrongPwd2,
    #wrongBranch,
    #wrongNum,
    #WrongPwdTeam1,
    #WrongPwdTeam2,
    #wrongCAEmail,
    #wrongNameCA,
    #wrongEmailCA,
    #WrongPwdCA1,
    #WrongPwdCA2,
    #wrongBranchCA,
    #wrongNumCA,
    #wrongEmailLog1,
    #wrongPwdLog1,
    .teamError {
        width: 47vw;
    }

    #registerCA {
        font-size: 1.4rem;
    }

    #recaptcha {
        margin-left: -1.5vw;
        transform: scale(0.9)
    }

    /* #teamReg {
        margin-top: -100px;
    } */

    #rstHeadbox {
        margin-top: -20px;
    }
}

@media (max-width:556px) {

    .register {
        width: 74vw;
    }

    .asRegister,
    .regInputname,
    .regButton,
    #otherOption,
    #otherCA,
    .regContinue,
    .forgotText {
        width: 55vw;
    }

    .regName,
    #wrongName,
    #wrongEmail,
    #WrongPwd1,
    #WrongPwd2,
    #wrongBranch,
    #wrongNum,
    #WrongPwdTeam1,
    #WrongPwdTeam2,
    #wrongCAEmail,
    #wrongNameCA,
    #wrongEmailCA,
    #WrongPwdCA1,
    #WrongPwdCA2,
    #wrongBranchCA,
    #wrongNumCA,
    #wrongEmailLog1,
    #wrongPwdLog1,
    .teamError {
        width: 54vw;
    }

    .regGender {
        width: 16.5vw;
        font-size: 0.9rem;
        margin-right: 2vw;
    }

    #LEye,
    #TEye,
    #CEye {
        left: 50vw;
    }

    .otpInput {
        width: 7vw;
        margin-right: 2vw;
    }

    #caFgtpass {
        top: 324px;
    }
}

@media(max-width:530px) {
    #recaptcha {
        margin-left: -3vw;
    }

    #memberReg {
        margin-top: 0px;
    }

    .register {
        padding-top: 0;
    }

}

@media (max-width:495px) {
    .register {
        width: 80vw;
    }

    .asRegister,
    .regInputname,
    .regButton,
    #otherOption,
    #otherCA,
    .regContinue,
    .forgotText {
        width: 61vw;
        height: 44px;
    }

    #LEye,
    #TEye,
    #CEye {
        left: 55vw;
    }

    .regGender {
        width: 19vw;
        height: 45px;
    }

    .regName,
    #wrongName,
    #wrongEmail,
    #WrongPwd1,
    #WrongPwd2,
    #wrongBranch,
    #wrongNum,
    #WrongPwdTeam1,
    #WrongPwdTeam2,
    #wrongCAEmail,
    #wrongNameCA,
    #wrongEmailCA,
    #WrongPwdCA1,
    #WrongPwdCA2,
    #wrongBranchCA,
    #wrongNumCA,
    #wrongEmailLog1,
    #wrongPwdLog1,
    .teamError {
        width: 58vw;
        font-size: 0.8rem;
    }

    .heading {
        font-size: 1.45rem;
    }

    .otpInput {
        width: 8vw;
    }

    .regFlex {
        margin-left: 3vw;
        margin-right: 3vw;
    }
}

@media(max-width:470px) {
    #recaptcha {
        margin-left: -2vw;
    }
}

@media(max-width:450px) {

    .otpInput {
        width: 8vw;
        margin-right: 2vw;
    }

    .register {
        width: 86vw;
    }

    .asRegister,
    .regInputname,
    .regButton,
    #otherOption,
    #otherCA,
    .regContinue,
    .forgotText {
        width: 67vw;
        height: 44px;
    }

    #LEye,
    #TEye,
    #CEye {
        left: 60vw;
    }

    .regGender {
        width: 20vw;
        margin-right: 2.2vw;
        height: 45px;
    }

    #registerCA {
        font-size: 1.3rem;
    }

    #back {
        width: 24px;
    }

    #regCA {
        margin-right: 2vw;
    }
/* 
    #teamReg {
        margin-top: -120px;
    } */

    #rstHeadbox {
        margin-top: -30px;
    }

    .regName,
    #wrongName,
    #wrongEmail,
    #WrongPwd1,
    #WrongPwd2,
    #wrongBranch,
    #wrongNum,
    #WrongPwdTeam1,
    #WrongPwdTeam2,
    #wrongCAEmail,
    #wrongNameCA,
    #wrongEmailCA,
    #WrongPwdCA1,
    #WrongPwdCA2,
    #wrongBranchCA,
    #wrongNumCA,
    #wrongEmailLog1,
    #wrongPwdLog1,
    .teamError {
        width: 65vw;
    }

    #recaptcha {
        margin-left: -7vw;
        transform: scale(0.8)
    }

    .forgotPass {
        top: 288px;
    }
}

@media(max-width:415px) {
    #forgotHead {
        font-size: 1.4rem;
    }

    #registerAs {
        font-size: 1.5rem;
    }

    .register {
        width: 90vw;
    }

    .regFlex {
        margin-right: 9vw;
    }

    .asRegister,
    .regInputname,
    .regButton,
    #otherOption,
    #otherCA,
    .regContinue,
    .forgotText {
        width: 65vw;
        height: 42px;
    }

    #LEye,
    #TEye,
    #CEye {
        left: 58vw;
    }

    .regName,
    #wrongName,
    #wrongEmail,
    #WrongPwd1,
    #WrongPwd2,
    #wrongBranch,
    #wrongNum,
    #WrongPwdTeam1,
    #WrongPwdTeam2,
    #wrongCAEmail,
    #wrongNameCA,
    #wrongEmailCA,
    #WrongPwdCA1,
    #WrongPwdCA2,
    #wrongBranchCA,
    #wrongNumCA,
    #wrongEmailLog1,
    #wrongPwdLog1,
    .teamError {
        width: 62vw;
    }

    #regCA {
        margin-right: 6vw;
    }

    .otpInput {
        width: 9vw;
    }

    #recaptcha {
        margin-left: -7vw;
    }
}

@media(max-width:380px) {

    #recaptcha {
        margin-left: -10vw;
        transform: scale(0.7)
    }

    #back {
        width: 28px;
    }

    #regCA {
        margin-right: 10vw;
    }

    #registerCA {
        line-height: 42px;
    }

    #caFgtpass {
        top: 318px;
    }

    .regFlex {
        margin-right: 10vw;
    }

    .cross {
        width: 16px;
    }
}

@media(max-width:360px) {
    #recaptcha {
        margin-left: -13vw;
        /* margin-left: 0; */
    }

    .forgotPass {
        top: 284px;
        right: 8vw;
    }

    .otpInput {
        width: 11vw;
        margin-right: 2.5vw
    }

    #caFgtpass {
        top: 336px;
    }
}

@media(max-width:340px) {
    #caFgtpass {
        top: 338px;
    }
}

@media(max-width:328px) {
    .forgotPass {
        top: 305px;
        right: 7vw;
    }

    .regFlex {
        margin-left: 2vw;
        margin-right: 12vw;
    }
}

@media screen and (max-height: 767px) {
    #rc-imageselect {
        transform: scale(0.77);
        -webkit-transform: scale(0.77);
        transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
    }
}

